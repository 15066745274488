<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">
          บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง
        </h1>
      </v-col>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="4" class="py-0 my-0"
            ><span>ค้นหาจาก</span>
            <v-select
              v-model="searchType"
              :items="searchTypeItems"
              placeholder="ค้นหาจาก"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4" class="py-0 my-0">
            <span>ค่าค้นหา</span>
            <v-text-field v-model="searchText" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="4" class="py-0 my-0"
            ><span>ลงวันที่</span>
            <v-menu
              v-model="menufrom1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom1"
                  label=""
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  @click:clear="dateFrom1 = null"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="dateFrom1"
                @input="menufrom1 = false"
                @click="$refs.menufrom1.save(dateFrom1)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="py-0 my-0">
            <span>ถึง</span>
            <v-menu
              v-model="menufrom2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom2"
                  label=""
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  @click:clear="dateFrom2 = null"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="dateFrom2"
                @input="menufrom2 = false"
                @click="$refs.menufrom2.save(dateFrom2)"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn @click="clearData()" class="mr-4">เคลียร์</v-btn
          ><v-btn color="#0074AA" dark @click="searchData()">ค้นหา</v-btn>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <!-- <v-col cols="4"  class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer> -->
          <v-col class="text-right">
            <ExportForm1
              :data="items"
              name="บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง"
              :jsonFields="jsonFields"
            />
            <v-btn
              @click="goToCreate()"
              color="#0074AA"
              dark
              v-show="userdata.user.isEdit"
              >เพิ่มบัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersForm1getAllForm9"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.Form1getAllForm9Img`]="{ item }">
              <v-img
                :src="item.Form1getAllForm9Img"
                width="120px"
                class="ma-1"
              ></v-img>
            </template>
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.activeFlag == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="ViewCate(item)" v-show="userdata.user.isView"
                  >mdi-eye</v-icon
                >
                <v-icon
                  @click="UpdateCate(item)"
                  class="mx-2"
                  v-show="userdata.user.isEdit"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteCate(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import ExportForm1 from "@/views/ExportExcel/ExportForm1.vue";
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
    ExportForm1,
  },
  data() {
    return {
      userdata: [],
      jsonFields: {
        เลขที่หนังสือ: "bookNo",
        รหัสแฟ้ม: "folderNo",
        วันที่ส่งเก็บ: "dateFrom",
        วันที่ลงส่งหนังสือ: "dateTo",
        เลขทะเบียนรับ: "formTo",
        เรื่อง: "subject",
        ไฟล์แนบ: "file",
        หมายเหตุ: "remark",

        // count: 1
        // createdAt: (...)
        // id: (...)
        // updatedAt: (...)
        // year: (...)
      },
      headersForm1getAllForm9: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับที่", value: "count", align: "center" },
        // { text: "เลขที่ประกาศ", value: "sortIndex1", align: "center" },
        // { text: "วันที่ส่งเก็บ", value: "dateFrom", align: "center" },
        { text: "รหัสแฟ้ม", value: "folderNo", align: "center" },
        { text: "ที่", value: "no", align: "center" },
        // { text: "เลขที่หนังสือ", value: "bookNo", align: "center" },
        { text: "ลงวันที่", value: "dateTo", align: "center" },
        // { text: "เลขทะเบียนรับ", value: "formTo", align: "center" },
        { text: "เรื่อง", value: "subject", align: "center" },
        // { text: "การปฏิบัติ", value: "activeFlag3", align: "center" },
        { text: "หมายเหตุ", value: "remark", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center", width: 200 },
      ],
      items: [],
      searchType: "",
      searchTypeItems: [
        { text: "--เลือก--", value: "" },
        // { text: "วันที่ส่งเก็บ", value: "dateFrom" },
        { text: "รหัสแฟ้ม", value: "folderNo" },
        // { text: "เลขที่หนังสือ", value: "bookNo" },
        { text: "ลงวันที่", value: "dateTo" },
        // { text: "เลขทะเบียนรับ", value: "formTo" },
        { text: "เรื่อง", value: "subject" },
        // { text: "กำหนดเวลาเก็บ", value: "dateExpired" },
      ],
      searchText: "",
      search: "",
      sortIndex: 0,
      loading: false,

      menufrom1: false,
      dateFrom1: "",
      menuto1: false,
      dateTo1: "",

      menufrom2: false,
      menuto2: false,
      dateFrom2: "",
      dateTo2: "",
      year: "",
    };
  },
  created() {
    this.year = JSON.parse(Decode.decode(localStorage.getItem("yearSelected")));
    this.userdata = JSON.parse(
      Decode.decode(localStorage.getItem("usersarabanberd"))
    );
    this.getAllForm9();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("usersarabanberd"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/categories/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    clearData() {
      this.searchType = "";
      this.searchText = "";
      this.dateFrom1 = null;
      this.dateFrom2 = null;
      // this.searchData();
    },
    async searchData() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/forms/form9?searchType=` +
          this.searchType +
          "&searchText=" +
          this.searchText +
          "&year=" +
          this.year
      );
      console.log("getAllForm9", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        // console.log(this.list);
      }
    },
    async getAllForm9() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/forms/form9?year=` + this.year
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllForm9", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateCate(val) {
      localStorage.setItem("form9data", Encode.encode(val));
      this.$router.push("EditForm9");
    },
    ViewCate(val) {
      localStorage.setItem("form9data", Encode.encode(val));
      this.$router.push("viewform9");
    },
    async DeleteCate(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("usersarabanberd"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/forms/form9/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllForm9();
        }
      });
    },
    goToCreate() {
      this.$router.push("form9");
    },
  },
};
</script>
